body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-tabs-tab {
  a {
    color: #929292;
  }
}

.ant-tabs-nav .ant-tabs-tab-active {
  a {
    color: #1574bb;
    font-weight: 500;
  }
}

.wrapper {
  width: 1224px;
  padding: 0 15px;
  margin: 0 auto !important;
}

.page-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.btn {
  text-align: center;
  padding: 4px 0;
  cursor: pointer;
}

.btn-type-border {
  width: 135px;
  border: 1px solid #fa8c16;
  box-sizing: border-box;
  border-radius: 2px;
  color: #fa8c16;
}

.btn-type-full {
  width: 135px;
  background: #f9921a;
  border-radius: 2px;
  color: #fff;
}

// .ant-table-thead {
//   .ant-table-cell {
//     color: #8c8c8c;
//     font-weight: 400;
//     opacity: 0.9;
//     font-size: 12px;
//     letter-spacing: 0.1em;
//   }
// }
