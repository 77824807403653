// global styles

:global {
  // html {
  //   height: inherit !important;
  // }

  body {
    height: 100%;
    background-color: #f1f3fa !important;
  }

  img {
    width: 100%;
  }

  hr {
    border: #eee solid 0.5px;
    margin: 0 !important;
  }

  #root {
    height: 100%;
  }

  .help-text{
    font-size: 12px;
    color: #cacaca
  }

  .input-title{
    display: inline-block;
    padding-bottom: 5px;
  }
  // .wrapper {
  //   padding: 0 20px;
  // }
  // .form {
  //   width: 100%;
  //   h2 {
  //     font-size: 18px;
  //     padding: 20px;
  //   }
  // }
  // .form-group {
  //   // display: flex;
  //   // align-items: baseline;
  //   // justify-content: flex-end;
  //   // // max-width: 430px;
  //   // margin: 0 auto 20px auto;
  //   &:nth-last-child(1) {
  //     margin: 0 auto;
  //   }
  // }

  // .label {
  //   margin: 0 10px 0 0;
  //   sup {
  //     color: #ff0000;
  //   }
  // }

  // .input {
  //   width: 280px;
  // }

  // .btn {
  //   // width: 140px;
  //   color: #000;
  //   &--action {
  //     color: #fff;
  //     background-color: #f16e38 !important;
  //     border-color: #f16e38 !important;
  //     &:hover {
  //       color: #fff;
  //       background-color: #c95b2e !important;
  //       border-color: #c95b2e !important;
  //     }
  //     &:focus {
  //       box-shadow: 0 0 0 0.2rem rgba(241, 110, 56, 0.5) !important;
  //       color: #fff;
  //     }
  //   }
  // }

  // .form-header {
  //   align-self: flex-start;
  //   h2 {
  //     display: inline-block;
  //     font-size: 18px;
  //     padding: 20px;
  //     margin: 0;
  //   }
  // }
  // .inputs-wrapper {
  //   padding: 40px 0 50px 0;
  // }

  // .form-text {
  //   margin: 20px 0;
  //   text-align: center;
  // }

  // .form-footer {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 10px 20px;
  //   background-color: #f7f8fa;
  // }

  // .input-wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   span {
  //     font-size: 12px;
  //     color: #cacaca;
  //   }
  // }
  // .form-wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 590px;
  //   margin: 0 auto;
  //   background-color: #fff;
  //   border-radius: 2px;
  // }

  // hr {
  //   width: 100%;
  //   margin: 0;
  // }
  // // .ant-select-selection {
  // //   width: 112px;
  // //   background-color: #f5222d;
  // //   color: #fff;
  // //   .ant-select-arrow {
  // //     color: #fff;
  // //   }
  // //   &:hover {
  // //     border-color: #ff9e9f;
  // //   }
  // //   &:focus {
  // //     border-color: #ff4d4f;
  // //     box-shadow: 0 0 0 2px rgba(255, 77, 79, .2);
  // //   }
  // // }

  // .ant-menu {
  //   background: #fafafa;
  // }
  // .ant-menu-item-selected {
  //   color: #ee4d12;
  // }

  // .ant-menu-inline,
  // .ant-menu-vertical,
  // .ant-menu-vertical-left {
  //   border-right: 1px solid transparent;
  // }

  // .ant-menu-item {
  //   &:hover {
  //     color: #ee4d12;
  //   }
  //   &:active {
  //     background: transparent;
  //   }

  //   &:after {
  //     border-right: 3px solid #ee4d12 !important;
  //   }
  // }

  // .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //   background-color: transparent;
  // }

  // .ant-tabs-nav .ant-tabs-tab {
  //   &:hover {
  //     color: #ee4d12;
  //   }
  // }

  // .ant-tabs-nav .ant-tabs-tab-active {
  //   color: #ee4d12;
  //   &:hover {
  //     color: #ee4d12;
  //   }
  // }
  // .ant-tabs-ink-bar {
  //   background-color: #ee4d12;
  // }

  // .content {
  //   margin: 24px 16px;
  //   padding: 24px;
  //   background: #fff;
  //   max-width: 765px;
  // }
  .ant-menu-horizontal {
    line-height: 64px !important;
  }
  .page-header {
    background-color: #fff !important;
    margin-bottom: 20px !important;
  }
}
